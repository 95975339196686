@layer components {
  .footnotes {
    @apply px-3 mx-3 text-sm leading-relaxed;
  }

  .footnotes-list {
    @apply list-decimal list-outside mx-4;
  }

  .footnote-item > p {
    @apply inline text-justify;
  }

  .footnote-item:target {
    @apply border-b underline;
  }

  .footnote-item:target::marker {
    @apply text-yellow-700;
  }

  .tippy-content p {
    text-indent: 1rem;
  }

  .footnotes-sep {
    @apply mt-8 mb-4 h-0 overflow-visible border-gray-400;
  }

  .footnote-ref,
  .footnote-backref {
    @apply text-blue-700;
  }

  .footnote-item > p:first-child {
    text-indent: 0;
  }

  .tippy-content p:last-child {
    @apply mb-0;
  }
}
