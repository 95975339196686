@layer base {
  p {
    @apply text-justify;

    text-indent: 1rem;
    text-indent: 2ch;
  }

  p + p {
    @apply mt-2;
  }
}

@layer utilities {
  a[href]:not(.never-underline):hover {
    @apply underline;
  }
}
