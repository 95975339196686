@layer base {
  :root {
    --wrapper-width: 80ch;
  }

  .wrapper {
    display: grid;
    grid-template-columns: 1fr min(var(--wrapper-width, 80ch), 100%) 1fr;
  }

  .wrapper > * {
    grid-column: 2;
  }
}

@layer utilities {
  .full-bleed {
    grid-column: 1 / 4;
    @apply w-full;
  }

  .mostly-full-bleed {
    grid-column: 1 / 4;
    @apply w-full max-w-6xl;
    @apply mx-auto;
  }
}
