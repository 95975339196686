@layer components {
  .nav-link,
  .footer-link {
    @apply block leading-4;
    @apply lg:inline-block;
  }

  .nav-link:not(:last-child) {
    @apply mr-5;
  }

  .nav-link.active {
    @apply underline text-gray-800;
  }

  .footer-link {
    @apply rounded p-1 text-center;
    @apply text-sm tracking-tight underline;
    @apply lg:mx-0;

    flex-basis: 33%;
  }

  .footer-link:hover {
    @apply text-blue-800 bg-gray-300;
  }

  .boxed-nav-link {
    @apply p-3 border rounded border-indigo-900 shadow-inner ml-auto;
  }

  .boxed-nav-link:hover {
    @apply bg-blue-800 text-blue-100 ring-2 ring-indigo-100;
  }

  .link-title {
    @apply tracking-tight;
  }

  .link-author {
    @apply mb-2 tracking-tight;
  }

  .permalink-anchor {
    @apply text-gray-700;
  }
}
