@layer base {
  .content {
    @apply w-full;
  }

  #main {
    @apply py-4 px-3 border space-y-4;
  }

  #main,
  #sidenav {
    @apply bg-blue-100 bg-opacity-75 border-gray-600;
  }

  .nav-container {
    display: grid;
    grid: [stack] 1fr / min-content [stack] 1fr;
    min-height: 100vh;
  }

  .nav-container > * {
    grid-area: stack;
  }

  #sidenav {
    @apply border-t border-l border-b;

    display: grid;
    grid-template-columns: [nav] 2fr [escape] 1fr;
    visibility: hidden;
  }
  @screen sm {
    .nav-container > * {
      grid-area: auto;
    }

    #sidenav {
      visibility: visible;
    }
  }
}

@layer components {
  .content h4 {
    @apply text-indigo-700;
  }

  .content h5 {
    @apply ml-0;
  }
}

@layer utilities {
  var {
    @apply not-italic;
  }
}
