@layer base {
  @font-face {
    font-display: block;
    font-family: "Fira Sans";
    font-style: normal;
    font-weight: 400;
    src: url("/fonts/fira-sans/fira-sans-v9-regular.woff2") format("woff2"),
      url("/fonts/fira-sans/fira-sans-v9-regular.woff") format("woff");
  }

  @font-face {
    font-display: block;
    font-family: "Fira Sans";
    font-style: normal;
    font-weight: 600;
    src: url("/fonts/fira-sans/fira-sans-v9-600.woff2") format("woff2"),
      url("/fonts/fira-sans/fira-sans-v9-600.woff") format("woff");
  }

  @font-face {
    font-display: block;
    font-family: "Fira Sans";
    font-style: normal;
    font-weight: 700;
    src: url("/fonts/fira-sans/fira-sans-v9-700.woff2") format("woff2"),
      url("/fonts/fira-sans/fira-sans-v9-700.woff") format("woff");
  }

  @font-face {
    font-display: block;
    font-family: "Fira Sans";
    font-style: italic;
    font-weight: 400;
    src: url("/fonts/fira-sans/fira-sans-v9-italic.woff2") format("woff2"),
      url("/fonts/fira-sans/fira-sans-v9-italic.woff") format("woff");
  }

  @font-face {
    font-display: block;
    font-family: Spectral;
    font-style: normal;
    font-weight: 400;
    src: url("/fonts/spectral/spectral-v6-regular.woff2") format("woff2"),
      url("/fonts/spectral/spectral-v6-regular.woff") format("woff");
  }

  @font-face {
    font-display: block;
    font-family: Spectral;
    font-style: normal;
    font-weight: 700;
    src: url("/fonts/spectral/spectral-v6-700.woff2") format("woff2"),
      url("/fonts/spectral/spectral-v6-700.woff") format("woff");
  }

  @font-face {
    font-display: block;
    font-family: Spectral;
    font-style: italic;
    font-weight: 400;
    src: url("/fonts/spectral/spectral-v6-italic.woff2") format("woff2"),
      url("/fonts/spectral/spectral-v6-italic.woff") format("woff");
  }

  @font-face {
    font-display: block;
    font-family: Spectral;
    font-style: normal;
    font-weight: 200;
    src: url("/fonts/spectral/spectral-v6-200.woff2") format("woff2"),
      url("/fonts/spectral/spectral-v6-200.woff") format("woff");
  }

  @font-face {
    font-display: block;
    font-family: "IBM Plex Mono";
    font-style: normal;
    font-weight: 400;
    src: url("/fonts/ibm-plex-mono/plex-mono-v5-regular.woff2") format("woff2"),
      url("/fonts/ibm-plex-mono/plex-mono-v5-regular.woff") format("woff");
  }

  @font-face {
    font-display: block;
    font-family: "IBM Plex Mono";
    font-style: normal;
    font-weight: 700;
    src: url("/fonts/ibm-plex-mono/plex-mono-v5-700.woff2") format("woff2"),
      url("/fonts/ibm-plex-mono/plex-mono-v5-700.woff") format("woff");
  }

  @font-face {
    font-display: block;
    font-family: "IBM Plex Mono";
    font-style: italic;
    font-weight: 400;
    src: url("/fonts/ibm-plex-mono/plex-mono-v5-italic.woff2") format("woff2"),
      url("/fonts/ibm-plex-mono/plex-mono-v5-italic.woff") format("woff");
  }
}
